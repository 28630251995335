import React from "react";
import theme from "theme";
import { Theme, Link, Image, Strong, Text, LinkBox, Box, Section, Span, Button, Structure, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { AiFillFacebook } from "react-icons/ai";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"MMB"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03:42:52.439Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03:42:52.439Z"} type={"image/x-icon"} />
		</Helmet>
		<Section
			sm-padding="8px 0 8px 0"
			quarkly-title="Header-5"
			padding="0 0 0 0"
			height="8%"
			width="100%"
			position="fixed"
			background="#ffffff"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				justify-content="space-between"
				height="100%"
				width="100%"
				display="flex"
				align-items="center"
			/>
			<Box
				display="flex"
				padding="5px 0 5px 0"
				justify-content="space-between"
				align-items="flex-start"
				flex-direction="row"
				sm-width="50%"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="flex-start"
				md-width="50%"
				lg-width="70%"
				md-justify-content="flex-start"
				width="100%"
			>
				<LinkBox
					flex-direction="row"
					href="/index"
					display="flex"
					grid-gap="12px"
					font="normal 300 .5em/1.5 --fontFamily-sans"
				>
					<Image
						src="https://uploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03:42:52.439Z"
						display="block"
						width="15%"
						height="15%"
						srcSet="https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/martini.png?v=2023-12-25T03%3A42%3A52.439Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text
						margin="0"
						md-margin="0px 0 0px 0"
						text-align="left"
						font="normal 500 3em/1.2 --fontFamily-sans"
						sm-margin="0px 0 0px 0"
						display="block"
						flex="0 1 auto"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Megan's Mobile Bartending
						</Strong>
					</Text>
				</LinkBox>
			</Box>
			<Box
				align-items="center"
				lg-justify-content="center"
				lg-align-items="flex-start"
				justify-content="flex-end"
				display="flex"
				lg-flex-direction="column"
				lg-margin="0px auto 0px auto"
				lg-min-width="300px"
				lg-max-width="1280px"
				lg-width="90%"
				lg-padding="24px 0px 48px 0px"
				height="100%"
				width="100%"
				align-content="center"
				padding="0px 5% 0px 0px"
			>
				<Components.QuarklycommunityKitMenu
					display="flex"
					filterMode="exclude"
					filterPages="/index"
					grid-gap="12px"
					lg-flex-direction="column"
					lg-padding="6px 0px 6px 0px"
					lg-margin="0px 0px 24px 0px"
					align-items="center"
					flex-wrap="wrap"
					overflow-x="visible"
					overflow-y="visible"
					lg-align-items="flex-start"
					rootId="/index"
				>
					<Override
						slot="link"
						color="--darkL2"
						hover-color="--primary"
						font="--base"
						text-decoration-line="initial"
						transition="color 0.1s ease 0s"
						lg-font="--lead"
					/>
					<Override
						slot="item-active"
						border-width="0 0 2px 0"
						border-style="solid"
						border-color="--color-darkL2"
						lg-border-width="0 0 0 2px"
					/>
					<Override slot="item" padding="6px 6px 6px 6px" lg-padding="6px 12px 6px 12px" />
					<Override slot="link-active" cursor="default" color="--darkL2" hover-color="--darkL2" />
					<Override slot="link-about" />
					<Override slot="link-index1">
						Home
					</Override>
				</Components.QuarklycommunityKitMenu>
				<Link
					font="normal 300 1em/1.5 --fontFamily-sans"
					text-decoration-line="initial"
					background="--color-darkL1"
					border-radius="8px"
					href="/index1"
					color="#ffffff"
					lg-margin="0px 0px 24px 0px"
					lg-padding="12px 18px 13px 18px"
					lg-font="--lead"
					display="block"
					padding=".5em .5em .5em .5em"
				>
					Get A Quote
				</Link>
				<Box
					width="25%"
					display="none"
					lg-width="100%"
					lg-flex-direction="column"
					lg-align-items="flex-start"
					lg-display="flex"
					justify-content="space-around"
					align-items="center"
					flex-wrap="wrap"
					lg-margin="32px 0px 0px 0px"
				>
					<SocialMedia
						instagram="https://instagram.com/instagram"
						margin="0px 0px 0px 0px"
						facebook="https://www.facebook.com/quarklyapp/"
						youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw"
						lg-display="flex"
						lg-grid-gap="12px"
					>
						<Override slot="link-twitter" margin="0px 0px 0px 5px">
							<div />
						</Override>
						<Override
							slot="link"
							border-radius="50%"
							background="transparent"
							hover-color="--light"
							display="flex"
							margin="0 5px 0 5px"
							padding="5x 5px 5px 5px"
							width="32px"
							height="32px"
							align-items="center"
							justify-content="center"
						/>
						<Override slot="icon" size="32px" border-radius="50px" color="--grey" />
						<Override slot="link-facebook" margin="0px 5px 0px 0px">
							<div />
						</Override>
					</SocialMedia>
				</Box>
			</Box>
		</Section>
		<Section
			display="flex"
			align-items="center"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6588a38b8e2e8e0021750305/images/nightlife-cel2edadasdasdebration-multi-colored-bar-establishment-generative-ai.jpg?v=2023-12-25T02:51:24.030Z) 0% 0% /100% repeat scroll padding-box"
			mix-blend-mode="normal"
			position="static"
			height="40%"
			overflow-x="visible"
			overflow-y="visible"
			padding="20% 0 5% 0"
		>
			<Override
				slot="SectionContent"
				justify-content="flex-end"
				align-items="center"
				flex-direction="column"
				align-self="flex-end"
			/>
			<Text
				margin="0px 0px 60px 0px"
				font="normal 900 5em/1.2 --fontFamily-sans"
				text-align="center"
				color="#ebebeb"
				text-shadow="5px 5px 5px #000000"
				sm-padding="20% 0px 0px 0px"
			>
				Craft Your Cocktail Experience{"\n\n"}
			</Text>
			<Button
				background="#ffffff"
				color="#000000"
				border-color="#000000"
				display="inline-block"
				text-align="center"
				padding=".5em 1em .5em 1em"
				margin="0px 0px 0px 0px"
				font="normal 500 1.7em/1.2 --fontFamily-sans"
				box-shadow="2px 2px 20px 0 rgba(0, 0, 0, 0.54)"
				border-width="2px"
				border-style="solid"
				href="/index1"
				type="link"
				text-decoration-line="initial"
				cursor="pointer"
			>
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="normal 300 1em/1.5 --fontFamily-sans"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Book Now
					</Strong>
				</Span>
			</Button>
			<Section padding="24px 0 24px 0" />
		</Section>
		<Structure
			cells-number-total="2"
			margin="0px 0px 0 0px"
			background="#eaeaea"
			border-width="1px"
			border-style="solid"
			border-color="rgba(48, 48, 48, 0.52)"
			overflow-x="auto"
			sm-overflow-x="hidden"
			sm-display="flex"
			display="flex"
			width="100%"
			height="30%"
			padding="2.5% 0px 2.5% 0px"
		>
			<Override slot="Content">
				<Override slot="cell-2">
					<Image
						src="https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=80"
						width="320px"
						max-width="100%"
						srcSet="https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3200&q=80 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Override>
				<Override slot="cell-1">
					<Image
						src="https://uploads.quarkly.io/6588a38b8e2e8e0021750305/images/Facetune_11-12-2023-21-12-46.jpg?v=2023-12-24T21:48:46.919Z"
						display="block"
						width="400px"
						border-width="4px"
						border-style="solid"
						srcSet="https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/Facetune_11-12-2023-21-12-46.jpg?v=2023-12-24T21%3A48%3A46.919Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/Facetune_11-12-2023-21-12-46.jpg?v=2023-12-24T21%3A48%3A46.919Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/Facetune_11-12-2023-21-12-46.jpg?v=2023-12-24T21%3A48%3A46.919Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/Facetune_11-12-2023-21-12-46.jpg?v=2023-12-24T21%3A48%3A46.919Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/Facetune_11-12-2023-21-12-46.jpg?v=2023-12-24T21%3A48%3A46.919Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/Facetune_11-12-2023-21-12-46.jpg?v=2023-12-24T21%3A48%3A46.919Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/Facetune_11-12-2023-21-12-46.jpg?v=2023-12-24T21%3A48%3A46.919Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Override>
				<Override slot="cell-0">
					<Section text-align="center" padding="10% 0 10% 0" sm-padding="40px 0" display="grid">
						<Override slot="SectionContent" display="grid" />
						<Text as="h1" font="normal 900 2.7em/1.2 --fontFamily-sans" md-font="--headline2" margin="2.5% 0 0 0">
							About Me
						</Text>
						<Text as="p" font="normal 300 1.7em/1.5 --fontFamily-sans" margin="20px 0 0 0">
							Hi there! My name is Megan, and I am a local Arizona mobile bartender. I have gone through bartending school, have a current Title 4 Certification, and insurance on my business. I am a solo act, but do have people willing to help with bigger events. Please let me know if I can add to your special event and we can come up with a plan and a quote! I look forward to working with you!{"\n\n"}
						</Text>
						<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column" />
						<Box padding="10px" />
					</Section>
				</Override>
				<Override slot="Cell 0th" display="grid" align-content="center" justify-items="center" />
			</Override>
		</Structure>
		<Section padding="24px 0 24px 0" background="#ffffff" border-width="1px" border-style="solid-top">
			<Text margin="0px 0px 0px 0px" text-align="center" font="--headline2">
				Specialty Drinks
			</Text>
		</Section>
		<Structure padding="0 70px 24px 70px" background="#ffffff">
			<Override slot="Content">
				<Override slot="cell-0">
					<Section display="flex" justify-content="center" align-items="center" flex-direction="column">
						<Override slot="SectionContent" align-items="center" justify-content="center" align-content="center" />
						<Image
							src="https://uploads.quarkly.io/6588a38b8e2e8e0021750305/images/moscow%20mule.jpg?v=2023-12-25T04:40:09.930Z"
							display="block"
							height="250px"
							align-self="center"
							srcSet="https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/moscow%20mule.jpg?v=2023-12-25T04%3A40%3A09.930Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/moscow%20mule.jpg?v=2023-12-25T04%3A40%3A09.930Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/moscow%20mule.jpg?v=2023-12-25T04%3A40%3A09.930Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/moscow%20mule.jpg?v=2023-12-25T04%3A40%3A09.930Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/moscow%20mule.jpg?v=2023-12-25T04%3A40%3A09.930Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/moscow%20mule.jpg?v=2023-12-25T04%3A40%3A09.930Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/moscow%20mule.jpg?v=2023-12-25T04%3A40%3A09.930Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text text-align="center">
							Moscow Mule
						</Text>
					</Section>
				</Override>
				<Override slot="cell-1">
					<Section
						width="80%"
						height="70%"
						align-self="center"
						align-items="center"
						justify-content="center"
					>
						<Override slot="SectionContent" align-items="center" />
						<Image
							src="https://uploads.quarkly.io/6588a38b8e2e8e0021750305/images/chocolate%20martini'.jpg?v=2023-12-25T04:40:52.587Z"
							display="block"
							align-self="center"
							height="250px"
							srcSet="https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/chocolate%20martini'.jpg?v=2023-12-25T04%3A40%3A52.587Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/chocolate%20martini'.jpg?v=2023-12-25T04%3A40%3A52.587Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/chocolate%20martini'.jpg?v=2023-12-25T04%3A40%3A52.587Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/chocolate%20martini'.jpg?v=2023-12-25T04%3A40%3A52.587Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/chocolate%20martini'.jpg?v=2023-12-25T04%3A40%3A52.587Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/chocolate%20martini'.jpg?v=2023-12-25T04%3A40%3A52.587Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/chocolate%20martini'.jpg?v=2023-12-25T04%3A40%3A52.587Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="16px 0px 16px 0px">
							Chocolate Martini
						</Text>
					</Section>
				</Override>
				<Override slot="cell-2">
					<Section>
						<Override slot="SectionContent" justify-content="center" align-items="center" />
						<Image
							src="https://uploads.quarkly.io/6588a38b8e2e8e0021750305/images/oldfashioned.jpg?v=2023-12-25T04:40:26.915Z"
							display="block"
							height="250px"
							align-self="center"
							srcSet="https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/oldfashioned.jpg?v=2023-12-25T04%3A40%3A26.915Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/oldfashioned.jpg?v=2023-12-25T04%3A40%3A26.915Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/oldfashioned.jpg?v=2023-12-25T04%3A40%3A26.915Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/oldfashioned.jpg?v=2023-12-25T04%3A40%3A26.915Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/oldfashioned.jpg?v=2023-12-25T04%3A40%3A26.915Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/oldfashioned.jpg?v=2023-12-25T04%3A40%3A26.915Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6588a38b8e2e8e0021750305/images/oldfashioned.jpg?v=2023-12-25T04%3A40%3A26.915Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="16px 0px 16px 0px">
							Old Fashioned
						</Text>
					</Section>
				</Override>
				<Override
					slot="Cell 0th"
					display="flex"
					align-items="center"
					justify-content="center"
					flex-direction="column"
				/>
			</Override>
		</Structure>
		<Section
			padding="80px 0 80px 0"
			background="#eaeaea"
			align-items="center"
			justify-content="center"
			align-content="center"
			border-width="1px"
			border-style="solid"
			border-color="rgba(48, 48, 48, 0.51)"
		>
			<Override
				slot="SectionContent"
				color="#ffffff"
				border-width="3px"
				border-color="#1a1a1a"
				border-style="solid"
				padding="24px 0px 24px 0px"
				box-shadow="5px 5px 40px 0 #6e6e6e"
				margin="0px 64px 0px 64px"
				background="#ffffff"
			/>
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--darkL2"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contact Me
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--darkL2" text-align="center">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						For any inquiries about our services or to discuss your upcoming event, please don't hesitate to get in touch. I am here to provide professional assistance and ensure your event is a success. Feel free to reach out through the contact form or use the contact details below. I am looking forward to serving you and making your event memorable!
					</Strong>
				</Text>
				<Link
					href="mailto:blank?hello@company.com"
					text-align="center"
					color="--darkL2"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					meganmobilebartending@gmail.com
				</Link>
				<Link
					href="tel:1234567899"
					text-align="center"
					color="--darkL2"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					(602) - 705 - 3102
				</Link>
			</Box>
			<LinkBox>
				<Icon category="ai" icon={AiFillFacebook} size="64px" color="rgba(0, 158, 255, 0.65)" />
			</LinkBox>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6588a38b8e2e8e0021750303"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});